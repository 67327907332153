define("discourse/plugins/paczki-topic-bump/discourse/templates/connectors/topic-footer-main-buttons-before-create/bump-topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShowField}}
  
    <DButton
      {{!-- {{on "click" (fn this.openModal)}} --}}
      @icon="fire"
      @action={{fn this.openModal}}
      @label="bump_topic.bump"
      @title="bump_topic.bump"
      class="btn btn-icon-text btn-default topic-footer-button topic_bump"
    />
  
  {{/if}}
  */
  {
    "id": "bUBo9nv/",
    "block": "[[[41,[30,0,[\"shouldShowField\"]],[[[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn btn-icon-text btn-default topic-footer-button topic_bump\"]],[[\"@icon\",\"@action\",\"@label\",\"@title\"],[\"fire\",[28,[37,2],[[30,0,[\"openModal\"]]],null],\"bump_topic.bump\",\"bump_topic.bump\"]],null],[1,\"\\n\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/paczki-topic-bump/discourse/templates/connectors/topic-footer-main-buttons-before-create/bump-topic.hbs",
    "isStrictMode": false
  });
});