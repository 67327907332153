define("discourse/plugins/paczki-topic-bump/discourse/initializers/bumped-notification", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "bumped-notification",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        const siteSettings = container.lookup("service:site-settings");
        if (!siteSettings.bump_topic_enabled) {
          return;
        }
        api.registerNotificationTypeRenderer("bumped", NotificationTypeBase => {
          return class extends NotificationTypeBase {
            icon = "fire";
            label = "Bumped";
          };
        }); // registerNotificationTypeRenderer
      });
    }
  };
});