define("discourse/plugins/paczki-topic-bump/discourse/connectors/topic-footer-main-buttons-before-create/bump-topic", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/plugins/paczki-topic-bump/discourse/components/modal/bump-topic-action"], function (_exports, _component, _object, _computed, _service, _bumpTopicAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BumTopic extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isEnabled", [(0, _computed.readOnly)("siteSettings.bump_topic_enabled")]))();
    #isEnabled = (() => (dt7948.i(this, "isEnabled"), void 0))();
    get isCategoryAllowed() {
      const allowedCategories = this.allowedCategories || [];
      const currentCategoryId = this.currentCategoryId;
      return allowedCategories.includes(currentCategoryId);
    }
    get isUserAllowed() {
      const topic = this.args.outletArgs.topic;
      const currentUserId = this.currentUser?.id;
      return currentUserId === topic.user_id;
    }
    get allowedCategories() {
      return (this.siteSettings.bump_topic_categories || "").split("|").map(id => parseInt(id, 10)).filter(id => id);
    }
    static #_6 = (() => dt7948.n(this.prototype, "allowedCategories", [(0, _object.computed)("siteSettings.bump_topic_categories")]))();
    get currentCategoryId() {
      this.categoryId = this.composer.topic.category_id;
      return this.categoryId;
    }
    get isTopicClosed() {
      const topic = this.args.outletArgs?.topic;
      return topic?.closed || false;
    }
    get shouldShowField() {
      return this.isEnabled && this.isCategoryAllowed && this.isUserAllowed && !this.isTopicClosed;
    }
    openModal(event) {
      event?.preventDefault();
      this.modal.show(_bumpTopicAction.default, {
        model: {
          topic: this.args.outletArgs.topic
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "openModal", [_object.action]))();
  }
  _exports.default = BumTopic;
});